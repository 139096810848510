import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { TbDiamond } from "react-icons/tb";
import { db } from "../backend/config";

const Vision = () => {
  const [data, setData] = useState(
    "We are dedicated to producing and delivering diamonds of the highest quality to meet our customers' needs."
  );
  useEffect(() => {
    getDataFromServer();
  }, []);
  const getDataFromServer = async () => {
    const docRef = doc(db, "website-data", "vision");
    const docSnap = await getDoc(docRef);
    setData(docSnap.data().text);
  };
  return (
    <section className="h-[100vh] w-full flex items-center justify-center flex-col relative overflow-hidden z-10 bg-black">
      <p className="text-white text-3xl md:text-4xl tracking-wider font-semibold uppercase">
        OUR <span className="text-[#f4b781]">VISION</span>
      </p>
      <p className="text-white text-xl md:text-2xl text-center mt-4 font-medium w-[80%] md:w-[50%] leading-10">
        {data && data}
      </p>
      <span className="flex justify-center items-center text-[#f4b781] text-4xl md:text-3xl mt-4">
        <TbDiamond />
      </span>
      <video
        src={require("../assets/vision.mp4")}
        className="absolute top-0 -z-10 w-auto min-w-full min-h-[100vh] max-w-none"
        autoPlay={true}
        loop
        muted
      ></video>
    </section>
  );
};

export default Vision;
