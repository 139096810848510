import React from "react";
import { useState } from "react";
import { TbDiamond } from "react-icons/tb";
import toast, { Toaster } from "react-hot-toast";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../backend/config";
import Airtable from "airtable";

const Contact = () => {
  const base = new Airtable({ apiKey: "key2magGtAnWNZuVU" }).base(
    "app2RfwxZowCka1eQ"
  );

  const [values, setValues] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const inputHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };
  const sendEmail = async (e) => {
    e.preventDefault();
    if (values.name && values.email && values.subject && values.message) {
      base("Contact").create(values, (err, record) => {
        if (err) {
          toast.error("Something Went Wrong!");
          return;
        }
        toast.success("Form Submitted!");
      });
      setValues({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
      const newContactRef = doc(collection(db, "contact"));
      await setDoc(newContactRef, { ...values, timestamp: serverTimestamp() });
    } else {
      toast.error("Fill All Details");
    }
  };

  return (
    <section
      className="h-[100vh] w-full flex items-center justify-center flex-col z-10 bg-black relative"
      id="Contact"
    >
      <div className="flex justify-center items-center mb-10">
        <span className="flex justify-center items-center mr-3 text-[#f4b781] text-3xl md:text-5xl">
          <TbDiamond />
        </span>
        <p className="text-white text-3xl md:text-4xl tracking-wider font-semibold uppercase">
          Contact <span className="text-[#f4b781]">Us</span>
        </p>
      </div>
      <form
        className="flex flex-col md:w-[35%] mx-auto w-[75%]"
        onSubmit={sendEmail}
      >
        <div className="md:flex md:justify-between">
          <div className="w-full md:w-1/2 md:mr-2">
            <input
              className="w-full mb-4 p-3 outline-none text-base font-Poppins bg-zinc-800 text-emerald-50 rounded-md placeholder:text-zinc-500 caret-[#f4b781]"
              type="text"
              id="fullname"
              name="name"
              required
              placeholder="Full Name"
              autoComplete="off"
              onChange={inputHandler}
              value={values.name}
            />
          </div>
          <div className="w-full md:w-1/2 md:ml-2">
            <input
              className="w-full mb-4 p-3 outline-none text-base font-Poppins bg-zinc-800 text-emerald-50 rounded-md placeholder:text-zinc-500 caret-[#f4b781]"
              type="email"
              id="email"
              name="email"
              required
              placeholder="Email Address"
              autoComplete="off"
              onChange={inputHandler}
              value={values.email}
            />
          </div>
        </div>
        <div>
          <input
            className="w-full mb-4 p-3 outline-none text-base font-Poppins bg-zinc-800 text-emerald-50 rounded-md placeholder:text-zinc-500 caret-[#f4b781]"
            type="text"
            id="subject"
            name="subject"
            required
            placeholder="Subject"
            autoComplete="off"
            onChange={inputHandler}
            value={values.subject}
          />
        </div>
        <div>
          <textarea
            className="w-full mb-4 p-3 outline-none text-base font-Poppins bg-zinc-800 text-emerald-50 rounded-md placeholder:text-zinc-500 caret-[#f4b781] resize-none"
            name="message"
            cols="30"
            rows="4"
            placeholder="Message Here"
            onChange={inputHandler}
            value={values.message}
          ></textarea>
        </div>
        <div className="flex justify-center">
          <button
            className="mx-auto bg-[#f4b781] py-2 rounded-lg font-semibold mt-3 px-6 flex justify-center items-center"
            onClick={sendEmail}
          >
            {/* <span className="flex justify-center items-center mr-2 text-[#000] text-xl">
              <TbDiamond />
            </span> */}
            Submit Form
          </button>
        </div>
      </form>
      <Toaster position="bottom-center" />
    </section>
  );
};

export default Contact;
