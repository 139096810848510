import { getIdToken, onAuthStateChanged, signOut } from "firebase/auth";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "./backend/config";
import toast, { Toaster } from "react-hot-toast";
import {
  collection,
  doc,
  getDocs,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { useState } from "react";

const Admin = () => {
  const [getintouch, setGetInTouch] = useState({});
  const [about, setAbout] = useState("");
  const [vision, setVision] = useState("");

  const navigation = useNavigate();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigation("/master/login");
      } else {
        getDataFromServer();
      }
    });
  }, [navigation]);

  const getDataFromServer = async () => {
    const querySnapshot = await getDocs(collection(db, "website-data"));
    querySnapshot.forEach((doc) => {
      if (doc.id === "about") {
        setAbout(doc.data().text);
      } else if (doc.id === "getintouch") {
        setGetInTouch(doc.data());
      } else if (doc.id === "vision") {
        setVision(doc.data().text);
      }
    });
  };

  const saveChangesHandler = async () => {
    try {
      toast.loading("Saving Data...");
      const aboutRef = doc(db, "website-data", "about");
      await updateDoc(aboutRef, {
        text: about,
        createdAt: serverTimestamp(),
      });
      const visionRef = doc(db, "website-data", "vision");
      await updateDoc(visionRef, {
        text: vision,
        createdAt: serverTimestamp(),
      });
      const getInTouchRef = doc(db, "website-data", "getintouch");
      await updateDoc(getInTouchRef, {
        ...getIdToken,
        createdAt: serverTimestamp(),
      });
      toast.dismiss();
      toast.success("Data Updated");
    } catch (error) {
      toast.dismiss();
      toast.error("Error Occured!");
      console.log(error);
    }
  };

  return (
    <section className="flex flex-col items-center min-h-[100vh] w-full bg-[#181818] pb-10">
      {/* Navbar */}
      <div className="flex justify-between w-[90%] items-center mt-4 flex-col md:flex-row">
        <p className="text-[#f4b781] font-semibold text-2xl uppercase tracking-widest">
          Dashboard
        </p>
        <div className="flex justify-center items-center mt-4 md:mt-0">
          <p
            className="text-[#fff] font-medium text-sm md:text-base mr-6 cursor-pointer"
            onClick={() =>
              window.open("https://airtable.com/shr7pA2lpITNvWzH6")
            }
          >
            Contact Responses
          </p>
          <p
            className="text-[#fff] font-medium text-sm md:text-base cursor-pointer"
            onClick={() =>
              signOut(auth)
                .then(() => {
                  toast.success("Logout Successfull");
                  navigation("/master/login");
                })
                .catch((error) => {
                  toast.error("Something Went Wrong");
                })
            }
          >
            Logout
          </p>
        </div>
      </div>
      {/* Main Panel */}
      <div className="w-[90%] md:w-[80%] mt-8 flex justify-center md:justify-between items-start flex-col md:flex-row">
        <div className="w-full md:w-[46%]">
          <p className="text-base md:text-xl font-medium text-white">
            Change About
          </p>
          <textarea
            cols="300"
            rows="10"
            value={about}
            onChange={(e) => setAbout(e.target.value)}
            className="mt-4 outline-none px-4 text-sm md:text-base py-2 rounded-md w-full"
          ></textarea>
        </div>
        <div className="w-full md:w-[46%]">
          <p className="text-base md:text-xl font-medium text-white">
            Change Vision
          </p>
          <textarea
            name=""
            id=""
            cols="300"
            rows="10"
            value={vision}
            onChange={(e) => setVision(e.target.value)}
            className="mt-4 outline-none px-4 text-sm md:text-base py-2 rounded-md w-full"
          ></textarea>
        </div>
      </div>
      <div className="w-[90%] md:w-[80%] mt-8 flex justify-between items-start flex-wrap">
        <div className="flex justify-center items-start flex-col w-full md:w-[46%]">
          <label htmlFor="number1" className="text-white leading-7">
            Phone Number 1
          </label>
          <input
            type="text"
            name="number1"
            className="text-sm md:text-base px-2 py-2 rounded w-full"
            value={getintouch.number1}
            onChange={(e) =>
              setGetInTouch({ ...getintouch, number1: e.target.value })
            }
          />
        </div>
        <div className="flex justify-center items-start flex-col w-full md:w-[46%]">
          <label htmlFor="number2" className="text-white leading-7">
            Phone Number 2
          </label>
          <input
            type="text"
            name="number2"
            className="text-sm md:text-base px-2 py-2 rounded w-full"
            value={getintouch.number2}
            onChange={(e) =>
              setGetInTouch({ ...getintouch, number2: e.target.value })
            }
          />
        </div>
        <div className="flex justify-center items-start flex-col w-full md:w-[46%] mt-3">
          <label htmlFor="wanumber1" className="text-white leading-7">
            Whatsapp Number 1
          </label>
          <input
            type="text"
            name="wanumber1"
            className="text-sm md:text-base px-2 py-2 rounded w-full"
            value={getintouch.wanum1}
            onChange={(e) =>
              setGetInTouch({ ...getintouch, wanum1: e.target.value })
            }
          />
        </div>
        <div className="flex justify-center items-start flex-col w-full md:w-[46%] mt-3">
          <label htmlFor="wanumber2" className="text-white leading-7">
            Whatsapp Number 2
          </label>
          <input
            type="text"
            name="wanumber2"
            className="text-sm md:text-base px-2 py-2 rounded w-full"
            value={getintouch.wanum2}
            onChange={(e) =>
              setGetInTouch({ ...getintouch, wanum2: e.target.value })
            }
          />
        </div>
        <div className="flex justify-center items-start flex-col w-[100%] mt-3">
          <label htmlFor="address1" className="text-white leading-7">
            Address 1
          </label>
          <textarea
            cols="300"
            rows="3"
            name="address1"
            value={getintouch.address1}
            onChange={(e) =>
              setGetInTouch({ ...getintouch, address1: e.target.value })
            }
            className="outline-none px-4 py-2 rounded-md w-full text-sm md:text-base"
          ></textarea>
        </div>
        <div className="flex justify-center items-start flex-col w-[100%] mt-3">
          <label htmlFor="address2" className="text-white leading-7">
            Address 2
          </label>
          <textarea
            cols="300"
            rows="3"
            name="address2"
            value={getintouch.address2}
            onChange={(e) =>
              setGetInTouch({ ...getintouch, address2: e.target.value })
            }
            className="outline-none px-4 py-2 rounded-md w-full text-sm md:text-base"
          ></textarea>
        </div>
        <button
          className="bg-[#f4b781] px-6 py-2 mt-6 rounded font-semibold mx-auto"
          onClick={saveChangesHandler}
        >
          Save Changes
        </button>
      </div>

      <Toaster position="bottom-center" />
    </section>
  );
};

export default Admin;
