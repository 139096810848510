import React from "react";
import Navbar from "./Navbar";
import { motion } from "framer-motion";

const Home = () => {
  return (
    <>
      <Navbar />
      <section
        className="h-[100vh] w-full flex items-center justify-center flex-col relative overflow-hidden z-10 bg-black"
        id="Home"
      >
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          src={require("../assets/logo.png")}
          alt="logo"
          className="h-[260px] md:h-[300px]"
        />
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="text-white text-4xl md:text-6xl mt-4 font-semibold uppercase"
        >
          TR Gems
        </motion.p>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="text-[#f4be8b] text-2xl md:text-3xl mt-4 font-medium uppercase"
        >
          Beyond Precious
        </motion.p>
        <video
          src={require("../assets/diamondVideo.mp4")}
          className="absolute top-0 -z-10 w-auto min-w-full min-h-[100vh] max-w-none"
          autoPlay={true}
          loop
          muted
          preload={"auto"}
        ></video>
      </section>
    </>
  );
};

export default Home;
