import {
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { TbDiamond } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { auth } from "./backend/config";
import toast, { Toaster } from "react-hot-toast";

const Login = () => {
  const navigation = useNavigate();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        navigation("/master/admin");
      }
    });
  }, [navigation]);
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const inputHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };
  const checkLoginHandler = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        toast.success("Login Successful");
      })
      .catch((error) => {
        toast.error("Something Went Wrong");
      });
  };

  const resetPassword = () => {
    if (data.email) {
      if (data.email.includes("@")) {
        sendPasswordResetEmail(auth, data.email)
          .then(() => {
            toast.success("Reset Link Send Successfully!");
          })
          .catch((error) => {
            toast.error("Error Occured!");
          });
      } else {
        toast.error("Enter Proper Email!");
      }
    } else {
      toast.error("Enter Proper Email!");
    }
  };

  return (
    <section className="flex flex-col justify-center items-center h-[100vh] w-full bg-[#181818]">
      <p className="font-semibold text-3xl text-[#f4b781] mb-6 uppercase tracking-wider">
        Admin Login
      </p>
      <span
        className="flex justify-center items-center text-[#f4b781] text-5xl absolute left-8 top-8 cursor-pointer"
        onClick={() => navigation("/")}
      >
        <TbDiamond />
      </span>
      <form
        className="flex flex-col md:w-[28%] mx-auto w-[75%]"
        onSubmit={checkLoginHandler}
      >
        <div className="md:flex md:justify-between">
          <div className="w-full">
            <input
              className="w-full mb-4 p-3 outline-none text-base font-Poppins bg-zinc-800 text-white rounded-md placeholder:text-zinc-500 caret-[#f4b781]"
              type="email"
              id="email"
              name="email"
              required
              placeholder="Email Address"
              autoComplete="off"
              onChange={inputHandler}
              value={data.email}
            />
          </div>
        </div>
        <div>
          <input
            className="w-full mb-4 p-3 outline-none text-base font-Poppins bg-zinc-800 text-white rounded-md placeholder:text-zinc-500 caret-[#f4b781]"
            type="password"
            id="password"
            name="password"
            required
            placeholder="*********"
            autoComplete="off"
            onChange={inputHandler}
            value={data.subject}
          />
        </div>
        <p className="text-white cursor-pointer my-1" onClick={resetPassword}>
          Reset Password
        </p>
        <div className="flex justify-center">
          <button
            className="mx-auto bg-[#f4b781] py-2 rounded-lg font-semibold mt-3 px-6 flex justify-center items-center"
            onClick={checkLoginHandler}
          >
            Login Now
          </button>
        </div>
      </form>
      <Toaster position="bottom-center" />
    </section>
  );
};

export default Login;
