import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../backend/config";
import { TbDiamond } from "react-icons/tb";
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";

const About = () => {
  const [data, setData] = useState(
    "TR Gems Diamonds LTD. was established in the year 2012 and carries the lineage of experience and quality service since the last 40 years. Our fundamental services include manufacturing and providing our established customers with single cut, full cut, Emerald cut , Pear cut, Marquise cut and GIA diamonds. Our operations are active in Surat and Mumbai. Our mission is to provide optimal quality of diamonds to our worldwide consumers."
  );
  useEffect(() => {
    getDataFromServer();
  }, []);
  const getDataFromServer = async () => {
    const docRef = doc(db, "website-data", "about");
    const docSnap = await getDoc(docRef);
    setData(docSnap.data().text);
  };
  return (
    <section
      className="h-[100vh] w-full flex items-center justify-center flex-col z-10 relative"
      id="About"
    >
      <ParallaxBanner style={{ height: "100vh", width: "100%" }}>
        <ParallaxBannerLayer
          image="https://firebasestorage.googleapis.com/v0/b/trgems-49e3f.appspot.com/o/about-bg.jpg?alt=media&token=85dd4182-f076-4ac3-9a3b-de677bd396bb&_gl=1*1e8swi2*_ga*OTY0ODUxOTkxLjE2ODU2ODM0NjE.*_ga_CW55HF8NVT*MTY4NTcxMzg0OS4zLjEuMTY4NTcxNjI4OS4wLjAuMA"
          speed={-22}
        />
        <ParallaxBannerLayer>
          <div className="bg-[#000000] bg-opacity-70 h-[100vh] w-full flex justify-center items-center flex-col z-10">
            <div className="flex justify-center items-center">
              <span className="flex justify-center items-center mr-3 text-[#f4b781] text-3xl md:text-5xl">
                <TbDiamond />
              </span>
              <p className="text-white text-3xl md:text-4xl tracking-wider font-semibold uppercase">
                About <span className="text-[#f4b781]">Us</span>
              </p>
            </div>
            <p className="text-white text-base md:text-lg text-center tracking-wider mt-10 w-[90%] md:w-[60%]">
              {data && data}
            </p>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    </section>
  );
};

export default About;
