import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const navigationHandler = (text) => {
    const element = document.getElementById(text);
    if (element) {
      const offsetTop = element.offsetTop;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
    setShowMenu(false);
  };

  return (
    <nav className="fixed top-0 left-0 right-0 backdrop-blur-md z-20">
      <div className="container mx-auto flex items-center justify-between px-4 py-2">
        <a href="/" className="text-white text-2xl font-bold">
          <img
            src={require("../assets/logo.png")}
            alt="Logo"
            width={60}
            className="cursor-pointer hover:scale-110 transition-all ease-linear duration-200"
          />
        </a>
        <button
          aria-label="Hamburger Menu Button"
          className="md:hidden text-white text-3xl focus:outline-none z-20"
          onClick={() => setShowMenu(!showMenu)}
        >
          {showMenu ? <AiOutlineClose /> : <AiOutlineMenu />}
        </button>
        <ul
          className={`md:flex md:items-center space-y-4 md:space-y-0 md:space-x-6 text-white ${
            showMenu
              ? "absolute h-[100vh] top-0 bg-black w-full left-0 flex justify-center items-center flex-col text-xl"
              : "hidden"
          }`}
        >
          <li
            onClick={() => navigationHandler("Home")}
            className="cursor-pointer font-semibold hover:text-[#f4be8b]"
          >
            Home
          </li>
          <li
            onClick={() => navigationHandler("About")}
            className="cursor-pointer font-semibold hover:text-[#f4be8b]"
          >
            About
          </li>
          <li
            onClick={() => navigationHandler("GetInTouch")}
            className="cursor-pointer font-semibold hover:text-[#f4be8b]"
          >
            Get In Touch
          </li>
          <li
            onClick={() => navigationHandler("Contact")}
            className="cursor-pointer font-semibold hover:text-[#f4be8b]"
          >
            Contact
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
