import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { TbDiamond } from "react-icons/tb";
import {
  AiOutlineMail,
  AiOutlinePhone,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../backend/config";
const GetInTouch = () => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to Clipboard!");
  };
  const [data, setData] = useState();
  useEffect(() => {
    getDataFromServer();
  }, []);
  const getDataFromServer = async () => {
    const docRef = doc(db, "website-data", "getintouch");
    const docSnap = await getDoc(docRef);
    setData(docSnap.data());
  };
  return (
    <section
      className="w-full flex flex-col z-10 bg-office-bg bg-no-repeat bg-cover relative"
      id="GetInTouch"
    >
      {data && (
        <div className="w-full min-h-[100vh] flex justify-center items-center flex-col bg-black bg-opacity-60">
          <div className="flex justify-center items-center mt-24 md:mt-0">
            <span className="flex justify-center items-center mr-3 text-[#f4b781] text-3xl md:text-5xl">
              <TbDiamond />
            </span>
            <p className="text-white text-3xl md:text-4xl tracking-wider font-semibold uppercase">
              Get In <span className="text-[#f4b781]">Touch</span>
            </p>
          </div>
          <div className="flex justify-center items-center mt-10 flex-col">
            <p className="text-white font-medium text-2xl md:text-3xl mb-8">
              Our Offices
            </p>
            <div className="flex flex-col items-center md:items-start justify-center w-[90%] md:w-[70%] md:justify-evenly md:flex-row">
              <p className="text-white flex justify-center items-center text-center w-[90%] md:w-[40%] md:mx-4 tracking-wide font-medium">
                {data.address1}
              </p>
              <div className="w-[2px] bg-[#f4b781] h-[100px] hidden md:block"></div>
              <div className="h-[2px] bg-[#f4b781] w-[90%] block md:hidden my-3"></div>
              <p className="text-white flex justify-center items-center text-center w-[90%] md:w-[40%] md:mx-4 tracking-wide font-medium">
                {data.address2}
              </p>
            </div>
            <div className="flex justify-center items-center mt-20 flex-col md:flex-row md:justify-evenly w-[90%] md:w-auto">
              <div className="bg-white bg-opacity-70 px-6 py-4 relative flex items-start justify-center rounded-md mb-8 md:mb-0 md:mr-8 w-[80%]">
                <div className="text-[#f4b781] text-2xl md:text-4xl bg-black block absolute -top-4 md:-top-8 px-4 py-2 rounded-lg shadow-lg shadow-[#f4b78180]">
                  <AiOutlinePhone />
                </div>
                <div>
                  <p
                    className="text-black cursor-pointer text-base md:text-lg mb-1 mt-4"
                    onClick={() => copyToClipboard(data.number1)}
                  >
                    {data.number1}
                  </p>
                  <p
                    className="text-black cursor-pointer text-base md:text-lg"
                    onClick={() => copyToClipboard(data.number2)}
                  >
                    {data.number2}
                  </p>
                </div>
              </div>
              <div className="bg-white bg-opacity-70 px-6 py-4 relative flex items-start justify-center rounded-md mb-8 md:mb-0 md:mr-8 w-[80%]">
                <div className="text-[#f4b781] text-2xl md:text-4xl bg-black block absolute -top-4 md:-top-8 px-4 py-2 rounded-lg shadow-lg shadow-[#f4b78180]">
                  <AiOutlineMail />
                </div>
                <div>
                  <p
                    className="text-black cursor-pointer text-base md:text-lg mt-6"
                    onClick={() => copyToClipboard(data.email)}
                  >
                    {data.email}
                  </p>
                </div>
              </div>
              <div className="bg-white bg-opacity-70 px-6 py-4 relative flex items-start justify-center rounded-md mb-20 md:mb-0 md:mr-8 w-[80%]">
                <div className="text-[#f4b781] text-2xl md:text-4xl bg-black block absolute -top-4 md:-top-8 px-4 py-2 rounded-lg shadow-lg shadow-[#f4b78180]">
                  <AiOutlineWhatsApp />
                </div>
                <div>
                  <p
                    className="text-black cursor-pointer text-base md:text-lg mb-1 mt-4"
                    onClick={() =>
                      window.open(
                        `http://wa.me/${data.wanum1.replace(" ", "")}"`
                      )
                    }
                  >
                    {data.wanum1}
                  </p>
                  <p
                    className="text-black cursor-pointer text-base md:text-lg"
                    onClick={() =>
                      window.open(
                        `http://wa.me/${data.wanum2.replace(" ", "")}`
                      )
                    }
                  >
                    {data.wanum2}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Toaster position="bottom-center" />
    </section>
  );
};

export default GetInTouch;
