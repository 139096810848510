import React from "react";

const Footer = () => {
  return (
    <div className="w-[100%] px-10 py-5 flex justify-between items-center z-10 bg-white flex-col md:flex-row">
      <p className="font-medium text-sm text-center">
        &#169; 2023 by TR Gems, All Rights Reserved.
      </p>
      <ul className="justify-center items-center hidden md:flex">
        <li
          className="mx-4 text-sm font-medium cursor-pointer text-black"
          onClick={() =>
            document
              .getElementById("Home")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          Home
        </li>
        <li
          className="mx-4 text-sm font-medium cursor-pointer text-black"
          onClick={() =>
            document
              .getElementById("About")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          About
        </li>
        <li
          className="mx-4 text-sm font-medium cursor-pointer text-black"
          onClick={() =>
            document
              .getElementById("GetInTouch")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          Get In Touch
        </li>
        <li
          className="mx-4 text-sm font-medium cursor-pointer text-black"
          onClick={() =>
            document
              .getElementById("Contact")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          Contact
        </li>
      </ul>
    </div>
  );
};

export default Footer;
