import React from "react";
import Home from "./Components/Home";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import GetInTouch from "./Components/GetInTouch";
import Vision from "./Components/Vision";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Login";
import Admin from "./Admin";
import { ParallaxProvider } from "react-scroll-parallax";

const App = () => {
  return (
    <Router>
      <Routes>
        {/* <Route
          path="/"
          element={
            <div>
              <ParallaxProvider>
                <Home />
                <About />
                <Vision />
                <GetInTouch />
                <Contact />
                <Footer />
              </ParallaxProvider>
            </div>
          }
        /> */}
        <Route path="/master/login" element={<Login />} />
        <Route path="/master/admin" element={<Admin />} />
        <Route
          path="*"
          element={
            <div>
              <ParallaxProvider>
                <Home />
                <About />
                <Vision />
                <GetInTouch />
                <Contact />
                <Footer />
              </ParallaxProvider>
            </div>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
